<template>
  <div class="row">
    <school-form :editing-existing="false" :header-text="'Create a Nebraska School'" :submit-text="'Create School'"/>
  </div>
</template>

<script>
import SchoolForm from "@/views/SuperUserViews/EducationalOrganizationManagement/School/SchoolForm";
export default {
  name: "SchoolCreate",
  components: {SchoolForm}
}
</script>

<style scoped>

</style>